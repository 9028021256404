import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { post, validateEmail } from "../../utils/io";
import useStyles from "./styles";

function NewOrganization() {
  const classes = useStyles();

  const [input, setInput] = useState({
    requestor_first_name: "",
    requestor_last_name: "",
    requestor_email: "",
    org_alias: "",
    org_name: "",
  });
  const [errEmail, setErrEmail] = useState("");
  const [errOrgAlias, setErrOrgAlias] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isRequesting, setIsRequesting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const validateOrgAlias = (value) => value && /^[a-z0-9-]{1,20}$/.test(value);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === "requestor_email") {
      const isvalidEmail = validateEmail(value);
      setErrEmail(!isvalidEmail ? "Invalid email address" : "");
    }
    if (name === "org_alias") {
      const isValidOrgAlias = validateOrgAlias(value);
      setErrOrgAlias(!isValidOrgAlias);
    }
    setInput((pre) => ({ ...pre, [name]: value }));
  };

  /* eslint-disable camelcase */
  const disableButtonSubmit = () => {
    const {
      requestor_first_name,
      requestor_last_name,
      requestor_email,
      org_alias,
      org_name,
    } = input;
    const isvalidEmail = validateEmail(requestor_email);
    const isValidOrgAlias = validateOrgAlias(org_alias);

    return (
      isRequesting ||
      !requestor_first_name ||
      !requestor_last_name ||
      !requestor_email ||
      !isValidOrgAlias ||
      !org_name ||
      !isvalidEmail
    );
  };

  const onSubmitForm = async () => {
    setIsRequesting(true);
    setErrMessage("");
    try {
      const res = await post("/org_requests", input);
      setIsRequesting(false);
      if (res.status !== 201) {
        return setErrMessage(
          res.data.detail || "Cannot create new organization"
        );
      }
      setIsSuccessful(true);
    } catch (error) {
      setIsRequesting(false);
    }
    return null;
  };

  return (
    <Grid className={classes.container}>
      {isSuccessful && (
        <div className={classes.panel}>
          <h2>Request Successful</h2>
          <p>
            Check your email for a confirmation link. It may take several
            minutes to arrive.
          </p>
          <p>
            Back to{" "}
            <Link to="/login" className={classes.link}>
              Login
            </Link>{" "}
            page
          </p>
        </div>
      )}
      {!isSuccessful && (
        <div className={classes.form}>
          <h3 className={classNames(classes.textCenter, classes.title)}>
            Create your organization to get started with Stel.
          </h3>
          <div className={classes.flex}>
            <TextField
              name="requestor_first_name"
              size="small"
              variant="outlined"
              placeholder="First Name"
              label="First Name"
              onChange={onChangeInput}
              value={input.requestor_first_name}
              className={classNames(classes.mrb20, classes.mrr15, classes.w100)}
            />
            <TextField
              name="requestor_last_name"
              size="small"
              variant="outlined"
              placeholder="Last Name"
              label="Last Name"
              onChange={onChangeInput}
              value={input.requestor_last_name}
              className={classNames(classes.mrb20, classes.w100)}
            />
          </div>
          <TextField
            name="requestor_email"
            label="Email"
            placeholder="Email"
            size="small"
            variant="outlined"
            onChange={onChangeInput}
            value={input.requestor_email}
            helperText={errEmail}
            error={errEmail}
            className={classNames(classes.mrb20, classes.w100, {
              [classes.mr0]: errEmail,
            })}
          />
          <TextField
            name="org_name"
            size="small"
            variant="outlined"
            label="Organization Name"
            placeholder="Organization Name"
            onChange={onChangeInput}
            value={input.org_name}
            className={classNames(classes.mrb20, classes.w100)}
          />
          <TextField
            name="org_alias"
            label="Organization Alias"
            placeholder="Organization Alias"
            size="small"
            variant="outlined"
            helperText="Only lowercase letters, numbers, and dashes are allowed. 20 character maximum."
            error={errOrgAlias}
            onChange={onChangeInput}
            value={input.org_alias}
            className={classNames(classes.mrb20, classes.w100)}
          />
          {errMessage && <p className={classes.errText}>{errMessage}</p>}
          <Button
            onClick={onSubmitForm}
            variant="contained"
            disabled={disableButtonSubmit()}
            color="primary"
            className={classes.w100}
          >
            {isRequesting && (
              <CircularProgress size={16} className={classes.progress} />
            )}{" "}
            Submit
          </Button>
        </div>
      )}
    </Grid>
  );
}
export default NewOrganization;
