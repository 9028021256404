import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {
  Box,
  Typography,
  Button,
  Chip,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
  Divider,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { get } from "../../../utils/io";
import {
  formatTroubleshootLabel,
  TROUBLESHOOT_TYPE_TO_FORM,
  FORMS_CHECKBOX_RETURN_IN_TAKE,
  CONNECTIVITY_ISSUES,
} from "../constants";
import TroubleshootOverviewCard from "../TroubleshootOverviewCard";

const defaultTicket = {
  details: {
    triage: {
      type: "",
      status: "",
      context: {},
      form: {},
      return_type: "",
    },
    intake: {
      form: {},
      replacement_hub_id: "",
      resolution: "",
    },
  },
};

function useQuery() {
  const location = useLocation();
  const search = location.hash.includes("?")
    ? location.hash.substring(location.hash.indexOf("?"))
    : "";

  return new URLSearchParams(search);
}

function HistoryDetailTabContent() {
  const history = useHistory();
  const { hubId } = useParams();
  const ticketId = useQuery().get("ticket");

  const [ticket, setTicket] = useState(defaultTicket);
  const [deviceModel, setDeviceModel] = useState({});

  const { triage, intake } = ticket.details;
  const triageFormFields = TROUBLESHOOT_TYPE_TO_FORM[triage.type] || [];

  const overviewValues = [
    {
      label: "Issue Type",
      value: `${formatTroubleshootLabel(triage.type)}${
        triage.type === "device_hub_connectivity"
          ? ` - ${
              triage.context?.device_model_id
                ? CONNECTIVITY_ISSUES.ONE_DEVICE
                : CONNECTIVITY_ISSUES.ANY_DEVICE
            }`
          : ""
      }`,
    },
    ...(triage.context?.issue_type
      ? [
          {
            label: "Issue Sub-Type",
            value: formatTroubleshootLabel(triage.context.issue_type),
          },
        ]
      : []),
    ...(triage.context?.device_model_id
      ? [
          {
            label: "Problem Device Model",
            value: `${deviceModel.make} ${deviceModel.display_name}`,
          },
        ]
      : []),
    {
      label: "Triage Result",
      value: `${formatTroubleshootLabel(triage.status)}${
        triage.return_type
          ? ` - ${formatTroubleshootLabel(triage.return_type)}`
          : ""
      }`,
    },
    ...(triage.returnType
      ? [
          {
            label: "Return Type",
            value: formatTroubleshootLabel(triage.returnType),
          },
        ]
      : []),

    ...(intake?.replacement_hub_id
      ? [
          {
            label: "Replaced With Hub",
            value: (
              <Link href={`/hubs/${intake.replacement_hub_id}`}>
                <Typography sx={{ fontSize: "14px" }}>
                  {intake.replacement_hub_id}
                </Typography>
              </Link>
            ),
          },
        ]
      : []),
    ...(intake?.resolution
      ? [
          {
            label: "Intake Resolution",
            value: formatTroubleshootLabel(intake.resolution),
          },
        ]
      : []),
    {
      label: "Created By",
      value:
        ticket.created_by?.first_name?.length > 0 &&
        ticket.created_by?.last_name?.length > 0
          ? `${ticket.created_by.first_name} ${ticket.created_by.last_name}`
          : `${ticket.created_by?.username || ""}`,
    },
    {
      label: "Created On",
      value: new Date(ticket.created_time).toLocaleString(),
    },
    {
      label: "Last Updated",
      value: new Date(ticket.updated_time).toLocaleString(),
    },
  ];

  useEffect(() => {
    const getTicket = async () => {
      const res = await get(`/troubleshoot/tickets/${ticketId}`);
      if (res.status === 200) {
        const { details, ...rest } = res.data;
        setTicket(() => ({
          ...rest,
          // Merging with default values so existential operator doesn't have to be used
          // everywhere
          // details: { ...pre.details, ...details },
          details,
        }));
      } else {
        enqueueSnackbar(res.data?.detail || "Unable to retrieve ticket", {
          variant: "error",
        });
      }
    };
    if (triage.context?.device_model_id) {
      const setMakeModels = async () => {
        const res = await get("/device_models");
        setDeviceModel(
          res.data.device_models.find(
            (model) => model.id === triage.context.device_model_id
          )
        );
      };
      setMakeModels();
    }

    getTicket();
  }, [ticketId, triage.context?.device_model_id]);

  return (
    <Stack sx={{ m: "24px" }} spacing="16px">
      <Stack flexDirection="row" sx={{ mb: "0px", gap: "12px" }}>
        <Button
          sx={{
            minWidth: "max-content",
            "& .MuiButton-startIcon": {
              margin: 0,
            },
            padding: 0,
          }}
          onClick={() => history.replace(`/hubs/${hubId}/troubleshoot#history`)}
          startIcon={
            <ArrowBackOutlinedIcon
              style={{ color: "#001737", fontSize: "26px" }}
            />
          }
        />
        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
          {`Ticket ${ticketId}`}
        </Typography>
        <Chip
          label={`${ticket?.details?.status || ""}`}
          size="small"
          sx={[
            {
              border: "1px solid #D32F2F",
              backgroundColor: "#F2DEDE",
              color: "#D32F2F",
              fontSize: "14px",
              fontWeight: 600,
              textTransform: "capitalize",
              mt: "2px",
            },
            ticket?.details?.status === "open" && {
              border: "1px solid #31883C",
              backgroundColor: "#E7FFEA",
              color: "#31883C",
            },
          ]}
          variant="outlined"
        />
      </Stack>
      <Box>
        <TroubleshootOverviewCard values={overviewValues || []} />
      </Box>
      <Divider />
      {triage?.form && (
        <DetailsSection
          title="Patient Triage Details"
          formFields={triageFormFields}
          formValues={triage.form}
          notes={triage.form.notes}
        />
      )}
      {intake?.form && (
        <>
          <Divider />
          <DetailsSection
            title="Return Intake Details"
            formFields={FORMS_CHECKBOX_RETURN_IN_TAKE}
            formValues={intake.form}
            notes={intake.form.notes}
          />
        </>
      )}
    </Stack>
  );
}

function DetailsSection({ title, formFields, formValues, notes }) {
  return (
    <Stack
      sx={{
        borderRadius: "4px",
        px: "16px",
      }}
    >
      <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
        {title}
      </Typography>
      {formFields.map((field) => (
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "14px",
            },
            "& .MuiFormControlLabel-label.Mui-disabled": {
              color: "#0000008F",
            },
            "& .MuiCheckbox-root.Mui-disabled": {
              color: "#0000008F",
            },
          }}
          key={field.name}
          label={field.title}
          control={
            <Checkbox
              size="small"
              name={field.name}
              checked={formValues[field.name] || false}
              disabled
            />
          }
        />
      ))}
      <Stack>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          Notes
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "#0000008F" }}>
          {notes || "None"}
        </Typography>
      </Stack>
    </Stack>
  );
}

DetailsSection.propTypes = {
  title: PropTypes.string.isRequired,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, title: PropTypes.string })
  ).isRequired,
  formValues: PropTypes.shape().isRequired,
  notes: PropTypes.string,
};
DetailsSection.defaultProps = {
  notes: "",
};

export default HistoryDetailTabContent;
