import { Box } from "@mui/material";

import { isEmpty } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { get, isAdmin } from "../../../utils/io";
import {
  TROUBLESHOOT_CATEGORIES,
  TROUBLESHOOT_MANAGEMENT,
  TROUBLESHOOT_SUBMIT_BUTTON,
  TROUBLESHOOT_TYPE,
} from "../constants";
import useSubmitTroubleshootForm from "../hooks/useSubmitTroubleshootForm";
import TroubleshootCategoryCard from "../TroubleshootCategoryCard";
import TroubleshootForm, { TROUBLESHOOT_FORM_DATA } from "../TroubleshootForm";
import TroubleshootOverviewCard from "../TroubleshootOverviewCard";
import HistoryDetailTabContent from "./HistoryDetailTabContent";
import HistoryTabContent from "./HistoryTabContent";

const COMPONENTS_CONTAIN_FORM = [
  TROUBLESHOOT_TYPE.HUB_POWER,
  TROUBLESHOOT_TYPE.HUB_CELLULAR,
  TROUBLESHOOT_TYPE.HUB_DEVICE_CONNECTIVITY,
  TROUBLESHOOT_TYPE.HEALTH_DEVICE_ISSUE,
  TROUBLESHOOT_TYPE.ACTIVE_RETURN,
];

function TroubleshootCategoryTabs(props) {
  const { hubId } = useParams();
  const { currentCategoryTab, ticketReturn, setTicketReturn, setTickets } =
    props;
  const [hub, setHub] = useState({});

  const disableReturnButton = ticketReturn?.details?.status === "open";
  const formMethods = useForm({
    defaultValues: TROUBLESHOOT_FORM_DATA?.[currentCategoryTab]?.defaultValues,
  });

  const { reset, handleSubmit, getValues, trigger } = formMethods;

  const {
    handleFormSubmit,
    completeLoading,
    saveLoading,
    requiredReplacementHub,
    setRequiredReplacementHub,
  } = useSubmitTroubleshootForm({
    formMethod: {
      getValues,
      trigger,
    },
    setTickets,
    ticketReturn,
    setTicketReturn,
  });

  useEffect(() => {
    if (currentCategoryTab !== TROUBLESHOOT_TYPE.ACTIVE_RETURN)
      reset(TROUBLESHOOT_FORM_DATA?.[currentCategoryTab]?.defaultValues);
  }, [currentCategoryTab, reset]);

  useEffect(() => {
    const getHubDetail = async () => {
      try {
        const hubRes = await get(`/hubs/${hubId}`);
        setHub(hubRes.data);
      } catch (error) {
        enqueueSnackbar(error, {
          variant: "error",
        });
      }
    };
    getHubDetail();
  }, [hubId]);

  const overviewData = useMemo(
    () => [
      {
        label: "Connection Status",
        value: hub.connected ? "Connected" : "Disconnected",
      },
    ],
    [hub]
  );

  // Other content is not data form
  const renderContentNotContainsForm = useMemo(() => {
    switch (currentCategoryTab) {
      case "history":
        return <HistoryTabContent />;
      default:
        return <HistoryDetailTabContent />;
    }
  }, [currentCategoryTab]);

  const troubleshootRightSection =
    TROUBLESHOOT_FORM_DATA?.[currentCategoryTab]?.rightSection;

  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <Box
        sx={{
          position: "fixed",
          width: 200,
          height: "calc(100% - 104px)",
          maxHeight: "calc(100% - 104px)",
          overflow: "auto",
          padding: "12px 0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "24px",
          background: "#ECECF2",
          zIndex: 10,
          justifyContent: "space-between",
        }}
      >
        {!isAdmin() && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            {TROUBLESHOOT_CATEGORIES.map((item) => (
              <TroubleshootCategoryCard
                key={item.label}
                sx={[
                  {
                    cursor: "pointer",
                    width: "172px",
                    height: "105px",
                    padding: "4px",
                  },
                  item.link === currentCategoryTab
                    ? {
                        background: "#F3F9FF",
                        border: "1px solid #1F79E2",
                      }
                    : {},
                ]}
                size={{ width: 45, height: 45 }}
                {...item}
                description=""
              />
            ))}
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          {TROUBLESHOOT_MANAGEMENT.map((item) => (
            <TroubleshootCategoryCard
              key={item.label}
              sx={[
                {
                  cursor: "pointer",
                  width: "172px",
                  height: "105px",
                  padding: "4px",
                },
                item.link === currentCategoryTab
                  ? {
                      background: "#F3F9FF",
                      border: "1px solid #1F79E2",
                    }
                  : {},
              ]}
              size={{ width: 45, height: 45 }}
              {...item}
              disabled={
                item.label === "Active Return" &&
                (isEmpty(ticketReturn) ||
                  (ticketReturn?.details?.status === "closed" &&
                    ticketReturn?.details?.triage?.status ===
                      "initiated_return"))
              }
            />
          ))}
        </Box>
      </Box>
      <Box width={200} height={1} />
      <Box
        sx={{
          display: "flex",
          flex: 1,
        }}
      >
        {COMPONENTS_CONTAIN_FORM.includes(currentCategoryTab) ? (
          <Box sx={{ width: "65%", padding: "24px" }}>
            <Box sx={{ marginBottom: "12px" }}>
              <TroubleshootOverviewCard values={overviewData} />
            </Box>
            <FormProvider {...formMethods}>
              <form
                onSubmit={handleSubmit(() =>
                  handleFormSubmit(TROUBLESHOOT_SUBMIT_BUTTON.RESOLVED.value)
                )}
              >
                <TroubleshootForm
                  type={currentCategoryTab}
                  ticketReturn={ticketReturn}
                  handleFormSubmit={handleFormSubmit}
                  completeLoading={completeLoading}
                  saveLoading={saveLoading}
                  requiredReplacementHub={requiredReplacementHub}
                  setRequiredReplacementHub={setRequiredReplacementHub}
                  disableReturnButton={disableReturnButton}
                />
              </form>
            </FormProvider>
          </Box>
        ) : (
          <Box width="100%">{renderContentNotContainsForm}</Box>
        )}
        {troubleshootRightSection && (
          <Box
            sx={{
              width: "35%",
              minHeight: "calc(100vh - 80px)",
              borderLeft: "1px solid #0000003B",
            }}
          >
            {troubleshootRightSection}
          </Box>
        )}
      </Box>
    </Box>
  );
}

TroubleshootCategoryTabs.propTypes = {
  currentCategoryTab: PropTypes.string.isRequired,
  ticketReturn: PropTypes.shape({
    id: PropTypes.string,
    details: PropTypes.shape({
      triage: PropTypes.shape({ status: PropTypes.string }),
      status: PropTypes.string,
    }),
  }).isRequired,
  setTickets: PropTypes.func.isRequired,
  setTicketReturn: PropTypes.func.isRequired,
};

export default TroubleshootCategoryTabs;
