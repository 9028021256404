import { Stack, Typography } from "@mui/material";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import React from "react";

function TroubleshootOverviewCard({ values: items }) {
  const labels = (
    <Stack spacing="8px">
      {items.map((item) => (
        <Typography
          key={item.label}
          fontSize={14}
          fontWeight={600}
        >{`${item.label}`}</Typography>
      ))}
    </Stack>
  );

  const values = (
    <Stack spacing="8px">
      {items.map((item) =>
        typeof item.value === "string" ? (
          <Typography
            key={uniqueId(item.value)}
            fontSize={14}
            color="#0000008F"
          >{`${item.value}`}</Typography>
        ) : (
          item.value
        )
      )}
    </Stack>
  );

  return (
    <Stack
      direction="row"
      spacing="64px"
      sx={{
        background: "#F3F9FF",
        gap: "12px",
        borderRadius: "4px",
        padding: "16px",
      }}
    >
      {labels}
      {values}
    </Stack>
  );
}

TroubleshootOverviewCard.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TroubleshootOverviewCard;
