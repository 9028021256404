import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoIcon from "@mui/icons-material/Info";
import {
  TextField,
  List,
  ListItemButton,
  Collapse,
  Divider,
  Tooltip,
  Stack,
  Typography,
  Box,
  Link,
} from "@mui/material";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  formatXealthUrl,
  renderMeasureDeliveryStatusIcon,
} from "../../../utils";

import { DOCS, MEASURE_TYPES } from "../../../utils/constants";
import Drawer from "../../Drawer";
import { PAYLOAD_SCHEMA } from "../../Endpoint/EndpointFormUtils";
import useStyles from "./styles";

function MeasureDetail(props) {
  const classes = useStyles();
  const { rowSelected, onClose } = props;
  const [endpointSelected, setEndpointSelected] = useState(null);

  const handleCloseDrawer = () => {
    onClose();
  };

  const handleSelectEndpoint = (id) => () => {
    if (id === endpointSelected) return setEndpointSelected(null);
    return setEndpointSelected(id);
  };

  const hubInfo = [
    {
      title: "Hub ID",
      value: rowSelected.hub?.hub_id,
    },
    {
      title: "Transmitting Hub ID",
      value: rowSelected.transmitting_hub?.hub_id,
    },
    {
      title: <TitleWithInfoIcon title="Hub Time" url={DOCS.MEASURE_TIME.url} />,
      value: rowSelected.hub_time
        ? new Date(rowSelected.hub_time).toLocaleString()
        : "Not reported",
      tooltip:
        "The time reported by the hub. This may differ from the device time.",
    },
  ];

  const deviceInfo = [
    {
      title: "Make/Model",
      value: `${rowSelected.device.make || "N/A"} ${
        rowSelected.device.model_display_name || "N/A"
      }`,
    },
    {
      title: "MAC Address",
      value: rowSelected.device.mac_address,
    },
    {
      title: (
        <TitleWithInfoIcon title="Device Time" url={DOCS.MEASURE_TIME.url} />
      ),
      value: rowSelected.reported_device_time
        ? new Date(rowSelected.reported_device_time).toLocaleString()
        : "Not reported",
      tooltip:
        "The time reported by the device. This may differ from the hub time.",
    },
  ];

  const getEndpointUrl = (endpoint) =>
    endpoint.payload_schema === PAYLOAD_SCHEMA.XEALTH_v1_0
      ? formatXealthUrl(endpoint.url)
      : endpoint.url;

  return (
    <Drawer
      open={!!rowSelected.id}
      width={400}
      anchor="right"
      onClose={handleCloseDrawer}
      title={
        <Stack direction="row">
          <div className={classes.alignItems}>
            {MEASURE_TYPES[rowSelected.type]?.icon || null}
          </div>
          <Stack>
            <h1
              className={classNames(
                classes.mr0,
                classes.capitalize,
                classes.fs20,
                classes.flex,
                classes.alignItems
              )}
            >
              {MEASURE_TYPES[rowSelected.type]?.label || rowSelected.type}
            </h1>
            <h4
              style={{ color: "grey" }}
              className={classNames(
                classes.mr0,
                classes.fs14,
                classes.flex,
                classes.alignItems
              )}
            >
              {new Date(rowSelected.timestamp).toLocaleString()}
            </h4>
          </Stack>
        </Stack>
      }
    >
      <h3 className={classNames(classes.mrb5, classes.mrl24, classes.fs14)}>
        Measure
      </h3>
      <BorderLeftContentBlock className="measure">
        {Object.keys(rowSelected.value).map((field) => {
          const measureTypeField =
            MEASURE_TYPES[rowSelected.type]?.fields[field.toLowerCase()];
          const name = measureTypeField?.label || field;
          const unit = measureTypeField?.unit || "";
          const value = `${rowSelected.value[field]} ${unit}`;
          return (
            <LineItem
              key={name}
              title={name}
              value={value}
              className={classes.w150}
            />
          );
        })}
      </BorderLeftContentBlock>
      <h3 className={classNames(classes.mrb5, classes.mrl24, classes.fs14)}>
        Hub
      </h3>
      <BorderLeftContentBlock className="hub">
        {hubInfo.map((data) => (
          <LineItem
            key={data.title}
            title={data.title}
            value={data.value}
            tooltip={data.tooltip}
          />
        ))}
      </BorderLeftContentBlock>
      <h3 className={classNames(classes.mrb5, classes.mrl24, classes.fs14)}>
        Device
      </h3>
      <BorderLeftContentBlock className="device">
        {deviceInfo.map((data) => (
          <LineItem
            key={data.title}
            title={data.title}
            value={data.value}
            tooltip={data.tooltip}
          />
        ))}
      </BorderLeftContentBlock>
      <List>
        {rowSelected.delivery?.map((item) => (
          <div key={item.id}>
            <ListItemButton
              onClick={handleSelectEndpoint(item.id)}
              className={classNames(classes.spaceBetween, classes.fs14, {
                [classes.boxShadown]: endpointSelected === item.id,
              })}
            >
              <div>
                <span className={classes.flex}>
                  {renderMeasureDeliveryStatusIcon(item.status)}
                  <span className={classes.mrl15}>
                    {getEndpointUrl(item.endpoint)}
                  </span>
                </span>
              </div>
              {endpointSelected ? (
                <ArrowDropUp className={classes.arrowIcon} />
              ) : (
                <ArrowDropDown className={classes.arrowIcon} />
              )}
            </ListItemButton>
            <Collapse
              in={endpointSelected === item.id}
              timeout="auto"
              unmountOnExit
            >
              <div className={classes.mrt15}>
                <BorderLeftContentBlock className="endpoint">
                  <LineItem
                    title="Request URL"
                    value={
                      <Box sx={{ overflowWrap: "anywhere" }}>
                        {getEndpointUrl(item.endpoint)}
                      </Box>
                    }
                  />
                  <LineItem
                    title="Status Code"
                    value={item.status_code || "No status"}
                  />
                </BorderLeftContentBlock>
                <PayloadTextArea
                  value={
                    item.response_payload
                      ? item.response_payload
                      : `Empty response body`
                  }
                  title="Response"
                  isItalic={!item.response_payload}
                />
                <PayloadTextArea
                  value={
                    item.request_payload
                      ? item.request_payload
                      : "Request in progress"
                  }
                  title="Request"
                  isItalic={!item.request_payload}
                />
              </div>
            </Collapse>
            <Divider />
          </div>
        ))}
      </List>
    </Drawer>
  );
}

function PayloadTextArea(props) {
  const { value, isItalic, title } = props;
  const [copied, setIsCopied] = useState(false);

  const onCopyContent = (content) => () => {
    copy(content);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const formattedValue =
    typeof value === "object"
      ? JSON.stringify(value, undefined, 4)
      : value.replace("\r", "\n");

  return (
    <Box>
      <Stack flexDirection="row">
        <Typography
          sx={{ m: "10px 0 10px 24px", fontSize: "14px", fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Tooltip title={copied ? "Copied!" : "Copy"} arrow>
          <ContentCopyIcon
            sx={{
              cursor: "pointer",
              fontSize: "18px",
              marginTop: "12px",
              marginLeft: "5px",
            }}
            onClick={onCopyContent(formattedValue)}
          />
        </Tooltip>
      </Stack>
      <Box>
        <TextField
          value={formattedValue}
          multiline
          disabled
          sx={{
            width: "100%",
            "& > .MuiInputBase-root > textarea": {
              fontFamily: "monospace",
            },
            "& .MuiInputBase-root": {
              background: "#ECECF3",
              borderRadius: 0,
              fontSize: 14,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fff",
              border: 0,
            },
            "& .MuiInputBase-root-MuiOutlinedInput-root": {
              borderRadius: 0,
            },
            ...(isItalic && { fontStyle: "italic" }),
          }}
        />
      </Box>
    </Box>
  );
}

function BorderLeftContentBlock(props) {
  const classes = useStyles();
  const { children, className, width } = props;
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (className) {
      const element = document.querySelector(`.${className}`);
      if (element) {
        setHeight(element.offsetHeight);
      }
    }
  }, [setHeight, className]);
  return (
    <div className={classNames(classes.hoverBg, classes.flex)}>
      <div className={classes.borderLeft} style={{ height, width }} />
      <div className={classNames(className)}>{children}</div>
    </div>
  );
}

function LineItem(props) {
  const { title, value, tooltip } = props;
  const content = (
    <Stack
      flexDirection="row"
      alignItems="flex-start"
      sx={{ fontSize: "14px" }}
    >
      <Box sx={{ width: 150, minWidth: 150, fontWeight: "bold" }}>{title}</Box>
      {value}
    </Stack>
  );

  return tooltip ? (
    <Tooltip
      title={<Typography sx={{ fontSize: "14px" }}>{tooltip}</Typography>}
      arrow
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
}

function TitleWithInfoIcon(props) {
  const { title, url } = props;
  return (
    <Stack flexDirection="row" gap="3px">
      {title}
      <Link
        href={url}
        target="_blank"
        rel="noopener"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <InfoIcon sx={{ fontSize: 14 }} />
      </Link>
    </Stack>
  );
}

TitleWithInfoIcon.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

LineItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.element,
  ]),
  tooltip: PropTypes.string,
};

LineItem.defaultProps = {
  value: "",
  tooltip: null,
};

BorderLeftContentBlock.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
};

BorderLeftContentBlock.defaultProps = {
  className: "",
  width: 4,
};
PayloadTextArea.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  isItalic: PropTypes.bool,
};
PayloadTextArea.defaultProps = {
  isItalic: false,
};

MeasureDetail.propTypes = {
  rowSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MeasureDetail;
