import { Autocomplete, Button, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { get } from "../../utils/io";
import StelModal from "../StelModal";

function OrgSelectionModal(props) {
  const { open, handleClose, onCreate } = props;
  const [orgs, setOrgs] = useState([]);
  const [input, setInput] = useState("");

  const { handleSubmit, control, watch } = useForm({});

  const watchOrg = watch("org");

  useEffect(() => {
    const getOrgs = async () => {
      const res = await get("admin/organizations", {
        alias: input,
        include_sandbox: false,
      });
      if (res.status !== 200) {
        return enqueueSnackbar({
          message: "Unable to retrieve organizations",
          variant: "error",
        });
      }
      return setOrgs(res.data.organizations);
    };
    getOrgs();
  }, [input]);

  const onSubmit = async ({ org }) => {
    handleClose();
    await onCreate(org.id);
  };

  const onClose = () => {
    setInput("");
    handleClose();
  };

  return (
    <StelModal
      title="Choose Organization"
      width={400}
      onClose={onClose}
      open={open}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="org"
          control={control}
          defaultValue={{ id: "", alias: "", name: "" }}
          shouldUnregister
          render={({ field: { onChange } }) => (
            <Autocomplete
              options={orgs}
              size="medium"
              getOptionLabel={(option) =>
                option?.id ? `${option.name} (${option.alias})` : ""
              }
              // Don't set the inputValue to the label when selecting a choice
              onInputChange={(_, value, reason) =>
                reason !== "reset" && setInput(value)
              }
              onChange={(_, value) => onChange(value)}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => (
                <TextField {...params} label="Organization" />
              )}
              clearOnBlur={false}
            />
          )}
        />
        <Button
          type="submit"
          disabled={!watchOrg?.id.length}
          variant="contained"
          sx={{
            float: "right",
            mt: 1.25,
          }}
        >
          Create
        </Button>
      </form>
    </StelModal>
  );
}

OrgSelectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default OrgSelectionModal;
