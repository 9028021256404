import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { isEmpty } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { get, isAdmin } from "../../../utils/io";
import RhfTextField from "../../form/RhfTextField";
import { useModal } from "../../hooks";
import HubAutoComplete from "../../HubAutocomplete";
import StelModal from "../../StelModal";
import { TROUBLESHOOT_SUBMIT_BUTTON } from "../constants";
import TroubleshootTable from "../TroubleshootTable";
import CheckboxGroup from "./components/CheckboxGroup";
import TroubleshootFormSelect from "./components/TroubleshootFormSelect";

const checkBoxList = ["confirmed_lights_blinking", "repair_attempted"];

const columns = [
  {
    field: "mac_address",
    headerName: "MAC Address",
    flex: 1,
  },
  {
    field: "make",
    headerName: "Make",
    flex: 1,
  },
  {
    field: "model_display_name",
    headerName: "Model",
    flex: 1,
  },
];

const RESOLUTIONS = [
  {
    value: "return_to_inventory",
    label: "Return To Inventory",
  },
  {
    value: "return_to_stel",
    label: "Return To Stel",
  },
  {
    value: "retired",
    label: "Retired",
  },
];

function ActiveReturnForm(props) {
  const {
    ticketReturn,
    handleFormSubmit,
    completeLoading,
    saveLoading,
    requiredReplacementHub,
    setRequiredReplacementHub,
  } = props;
  const {
    control,
    setValue,
    formState: { errors },
    reset,
  } = useFormContext();

  const { hubId } = useParams();
  const [devices, setDevices] = useState([]);
  const [newReplacementHubId, setNewReplacementHubId] = useState("");
  const [isOpenModelCloseTicket, setIsOpenModelCloseTicket] = useState(false);

  const { isModal, openModal, closeModal } = useModal();
  const returnType = ticketReturn?.details?.triage?.return_type || "";
  const replaceHubId = ticketReturn.details?.intake?.replacement_hub_id || "";

  const onClickCloseModalSelectHub = () => {
    setNewReplacementHubId("");
    closeModal();
  };

  const handleModelCloseTicket = () => {
    setIsOpenModelCloseTicket((prev) => !prev);
  };
  useEffect(() => {
    reset({
      confirmed_lights_blinking: false,
      repair_attempted: false,
      notes: "",
      replacement_hub_id: "",
    });
  }, [ticketReturn, reset]);

  useEffect(() => {
    const getDevices = async () => {
      const res = await get("/devices", {
        bound_esn: ticketReturn.details?.intake?.replacement_hub_id || hubId,
      });
      if (res.status === 200) {
        setDevices(res.data.devices);
      } else {
        enqueueSnackbar(res.data.detail, {
          variant: "error",
        });
      }
    };
    getDevices();
  }, [hubId, ticketReturn.details?.intake?.replacement_hub_id]);

  useEffect(() => {
    if (replaceHubId) setRequiredReplacementHub();
  }, [replaceHubId, setRequiredReplacementHub]);

  useEffect(() => {
    const { intake } = ticketReturn?.details ?? {};

    const {
      form,
      resolution,
      replacement_hub_id: replacementHubId,
    } = intake ?? {};

    if (!isEmpty(form)) {
      const { notes } = form;
      reset({
        repair_attempted: form.repair_attempted,
        confirmed_lights_blinking: form.confirmed_lights_blinking,
        notes,
        resolution,
        replacement_hub_id: replacementHubId,
      });
      setNewReplacementHubId(replacementHubId);
    }
  }, [ticketReturn, reset]);

  return (
    <Stack spacing={2}>
      <Stack justifyContent="space-between" flexDirection="row">
        <Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
          Open Ticket #{ticketReturn.id}
        </Typography>
        <LoadingButton
          color="error"
          variant="contained"
          onClick={handleModelCloseTicket}
          disabled={isAdmin()}
        >
          Cancel Ticket
        </LoadingButton>
      </Stack>
      <Stack gap="20px">
        {!["kit_returned", "device_replaced"].includes(returnType) && (
          <Stack gap="10px">
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              Replacement Hub
            </Typography>
            <Stack flexDirection="row" gap="10px">
              <Button
                size="medium"
                variant="contained"
                onClick={openModal}
                disabled={isAdmin()}
              >
                Select Hub
              </Button>
              {replaceHubId && (
                <TextField value={replaceHubId} disabled size="small" />
              )}
            </Stack>
            {requiredReplacementHub && (
              <Typography
                sx={{ color: red[500], fontSize: "12px", ml: "10px" }}
              >
                {requiredReplacementHub}
              </Typography>
            )}
            {!!devices.length && (
              <TroubleshootTable
                columns={columns}
                rows={devices}
                title="Bound Devices"
                hideFooter
              />
            )}
          </Stack>
        )}
        <Divider flexItem />
        <Box
          sx={{
            background: "#F7F7F7",
            borderRadius: 2,
            padding: "12px",
            width: "calc(100% - 24px)",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography fontSize={20} fontWeight={600}>
            Return Intake (Complete after receiving hub)
          </Typography>
          <CheckboxGroup list={checkBoxList} control={control} />
          <RhfTextField
            name="notes"
            control={control}
            label="Notes"
            size="small"
            rows={5}
            multiline
            variant="outlined"
          />
          <TroubleshootFormSelect
            rules={{
              required: "Resolution is required",
            }}
            errors={errors}
            label="Resolution *"
            labelId="select-label"
            options={RESOLUTIONS}
            name="resolution"
            control={control}
            defaultValue={ticketReturn.details?.intake?.resolution}
            key={ticketReturn.details?.intake?.resolution}
          />
          <Stack flexDirection="row" gap="10px" justifyContent="flex-end">
            <LoadingButton
              color="primary"
              variant="outlined"
              loading={saveLoading}
              disabled={completeLoading || isAdmin()}
              onClick={() =>
                handleFormSubmit?.(TROUBLESHOOT_SUBMIT_BUTTON.SAVE_TICKET.value)
              }
            >
              {TROUBLESHOOT_SUBMIT_BUTTON.SAVE_TICKET.label}
            </LoadingButton>
            <LoadingButton
              disabled={saveLoading || isAdmin()}
              loading={completeLoading}
              color="primary"
              variant="contained"
              onClick={() =>
                handleFormSubmit?.(
                  TROUBLESHOOT_SUBMIT_BUTTON.ACTIVE_RETURN_COMPLETE.value
                )
              }
            >
              {TROUBLESHOOT_SUBMIT_BUTTON.ACTIVE_RETURN_COMPLETE.label}
            </LoadingButton>
          </Stack>
        </Box>
      </Stack>
      <StelModal
        open={isModal}
        onClose={onClickCloseModalSelectHub}
        title="Select Replacement Hub"
        width={400}
      >
        <Stack gap="10px">
          {devices.length > 0 && (
            <Typography>
              All {devices.length} devices bound to {hubId} will be re-bound to
              the hub you select below.
            </Typography>
          )}
          <HubAutoComplete
            filterOptions={(options) =>
              options.filter((option) => option.hub_id !== hubId)
            }
            onChange={(_, replacementHub, reason) => {
              setNewReplacementHubId(
                reason === "clear" ? "" : replacementHub.hub_id
              );
              setValue("replacement_hub_id", replacementHub?.hub_id || "");
            }}
            value={{
              hub_id:
                newReplacementHubId ||
                ticketReturn.details?.intake?.replacement_hub_id,
            }}
            size="small"
          />
          <Stack flexDirection="row" justifyContent="flex-end">
            <LoadingButton
              variant="contained"
              color="primary"
              loading={saveLoading}
              onClick={() => {
                closeModal();
                handleFormSubmit?.(
                  TROUBLESHOOT_SUBMIT_BUTTON.SAVE_TICKET.value
                );
              }}
              disabled={!newReplacementHubId}
            >
              Select
            </LoadingButton>
          </Stack>
        </Stack>
      </StelModal>
      <StelModal
        open={isOpenModelCloseTicket}
        onClose={handleModelCloseTicket}
        width="500px"
      >
        <Typography
          sx={{ marginBottom: "10px", fontWeight: 600, fontSize: "20px" }}
        >
          Cancel Ticket
        </Typography>
        <Typography sx={{ marginBottom: "4px", fontSize: "16px" }}>
          A canceled ticket can no longer be changed or marked for a replacement
          hub. Are you sure you want to cancel this ticket?
        </Typography>
        {newReplacementHubId && (
          <Typography sx={{ fontSize: "16px", fontStyle: "italic" }}>
            (Please note that bindings moved to{" "}
            <span style={{ fontStyle: "normal", fontWeight: 600 }}>
              {newReplacementHubId}
            </span>{" "}
            will be returned to{" "}
            <span style={{ fontStyle: "normal", fontWeight: 600 }}>
              {hubId}
            </span>
            .)
          </Typography>
        )}
        <Stack
          flexDirection="row"
          gap="10px"
          justifyContent="flex-end"
          marginTop="24px"
        >
          <LoadingButton
            color="primary"
            variant="outlined"
            onClick={handleModelCloseTicket}
          >
            No
          </LoadingButton>
          <LoadingButton
            color="primary"
            variant="contained"
            disabled={saveLoading}
            loading={completeLoading}
            onClick={() =>
              handleFormSubmit?.(TROUBLESHOOT_SUBMIT_BUTTON.CANCEL_TICKET.value)
            }
          >
            Yes
          </LoadingButton>
        </Stack>
      </StelModal>
    </Stack>
  );
}

ActiveReturnForm.propTypes = {
  ticketReturn: PropTypes.shape({
    id: PropTypes.string,
    details: PropTypes.shape({
      id: PropTypes.string,
      intake: PropTypes.shape({
        replacement_hub_id: PropTypes.string,
        resolution: PropTypes.string,
        form: {
          confirmed_lights_blinking: PropTypes.bool,
          repair_attempted: PropTypes.bool,
          nodes: PropTypes.string,
        },
      }),
      triage: PropTypes.shape({ return_type: PropTypes.string }),
    }),
  }),
  handleFormSubmit: PropTypes.func,
  completeLoading: PropTypes.bool.isRequired,
  requiredReplacementHub: PropTypes.string,
  setRequiredReplacementHub: PropTypes.func,
  saveLoading: PropTypes.bool.isRequired,
};

ActiveReturnForm.defaultProps = {
  ticketReturn: {},
  requiredReplacementHub: null,
  setRequiredReplacementHub: () => {},
  handleFormSubmit: () => {},
};

export default ActiveReturnForm;
