import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Chip, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { formatXealthUrl } from "../../utils";
import { DOCS, SNACKBAR_STATUS } from "../../utils/constants";

import { get, hasPermission, post } from "../../utils/io";
import { ProtectedButton } from "../Authz/Protected";
import DefaultHubGroupIndicator from "../DefaultHubGroupIndicator";
import { PAYLOAD_SCHEMA } from "../Endpoint/EndpointFormUtils";
import RhfTextField from "../form/RhfTextField";
import MainContentContainer from "../Layouts/MainContentContainer";
import ShowSnackBar from "../Snackbar";
import StelDataGrid from "../StelDataGrid";
import StelModal from "../StelModal";

function HubGroupList() {
  const history = useHistory();

  const defaultSearchCriteria = {
    groupName: "",
    endpoint: "",
  };

  const [hubGroups, setHubGroups] = useState([]);
  const [input, setInput] = useState({
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(defaultSearchCriteria);
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    status: "",
  });

  const { handleSubmit, control, reset } = useForm({
    defaultValues: defaultSearchCriteria,
  });

  async function getHubGroups(criteria) {
    setLoading(true);
    const res = await get("hub_groups", {
      name: criteria.groupName,
      url: criteria.endpoint,
    });
    if (res.status === 200) {
      setHubGroups(res.data.hub_groups);
    }
    setLoading(false);
  }

  useEffect(() => {
    getHubGroups(searchCriteria);
  }, [searchCriteria]);

  const columns = [
    {
      field: "name",
      headerName: "Group Name",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              marginRight: "10px",
              maxWidth: "280px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {params.value}
          </span>
          {params.row.is_default && <DefaultHubGroupIndicator />}
        </Box>
      ),
    },
    {
      field: "endpoints",
      headerName: "Endpoints",
      renderCell: (params) => {
        const endpointCount = params.value.length;
        let url = "";
        if (endpointCount > 0) {
          const firstEndpoint = params.value[0];
          url =
            firstEndpoint.payload_schema === PAYLOAD_SCHEMA.XEALTH_v1_0
              ? formatXealthUrl(firstEndpoint.url)
              : firstEndpoint.url;
        }
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                marginRight: "10px",
                maxWidth: "280px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {url}
            </span>
            {endpointCount - 1 > 0 && (
              <Chip color="primary" label={`+${endpointCount - 1}`} />
            )}
          </Box>
        );
      },
      flex: 1.3,
    },
    {
      field: "hub_count",
      headerName: "Hub Count",
      flex: 0.7,
    },
  ];

  const closeModal = () => {
    setInput((pre) => ({
      ...pre,
      name: "",
      hubId: "",
      groupId: "",
    }));
    setShowModal(false);
    getHubGroups(defaultSearchCriteria);
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInput((pre) => ({ ...pre, [name]: value }));
  };

  const submitModal = async (e) => {
    e.preventDefault();

    const res = await post("hub_groups", {
      name: input.name,
    });
    if (res.status !== 201) {
      return setSnackbar({
        message: res.data.detail,
        open: true,
        status: SNACKBAR_STATUS.ERROR,
      });
    }

    if (hasPermission("UpdateHubGroups")) {
      return history.push(`/hub-group/${res.data.id}`);
    }
    return closeModal();
  };

  const resetForm = () => {
    reset();
    setSearchCriteria(defaultSearchCriteria);
  };

  return (
    <MainContentContainer title="Hub Groups" docsUrl={DOCS.HUB_GROUP.url}>
      <Stack sx={{ gap: "24px" }}>
        <ShowSnackBar
          {...snackbar}
          onClose={() => setSnackbar({ message: "", open: false, status: "" })}
        />
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <form onSubmit={handleSubmit(setSearchCriteria)}>
            {[
              {
                label: "Group Name",
                name: "groupName",
              },
              {
                label: "Endpoints",
                name: "endpoint",
              },
            ].map((field) => (
              <RhfTextField
                name={field.name}
                control={control}
                key={field.name}
                sx={{ width: "150px", marginRight: "20px !important" }}
                label={field.label}
                size="small"
                variant="standard"
              />
            ))}
            <Button
              sx={{ mt: "10px" }}
              color="primary"
              type="submit"
              variant="contained"
            >
              Search
            </Button>
            <Button
              sx={{ mt: "10px", ml: "10px" }}
              color="error"
              variant="contained"
              onClick={resetForm}
            >
              Clear
            </Button>
          </form>
          <ProtectedButton
            permission="CreateHubGroups"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setShowModal(true)}
            variant="contained"
          >
            Create
          </ProtectedButton>
        </Stack>
        <StelDataGrid
          rows={hubGroups}
          columns={columns}
          loading={loading}
          rowClickPermission="UpdateHubGroups"
          onRowClick={(row) => history.push(`/hub-group/${row.id}`)}
        />
        <StelModal
          open={showModal}
          onClose={closeModal}
          title="Create Hub Group"
          width={400}
        >
          <form onSubmit={submitModal}>
            <TextField
              sx={{ mb: "10px", width: "100%" }}
              label="Name"
              onChange={onChangeInput}
              value={input.name}
              name="name"
              variant="outlined"
              size="small"
              required
            />
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{ width: "150px" }}
                type="submit"
                color="primary"
                variant="contained"
              >
                Create
              </Button>
            </Box>
          </form>
        </StelModal>
      </Stack>
    </MainContentContainer>
  );
}

export default HubGroupList;
