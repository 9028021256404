import { Stack, Typography } from "@mui/material";
import { debounce } from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { get } from "../../../utils/io";
import { formatTroubleshootLabel } from "../constants";

import TroubleshootTable from "../TroubleshootTable";

const COLUMNS = [
  {
    field: "created_time",
    flex: 1,
    headerName: "Date",
    valueGetter: (params) => new Date(params.row.created_time).toLocaleString(),
  },
  {
    field: "status",
    headerName: "Status",
    valueGetter: (params) => formatTroubleshootLabel(params.row.details.status),
  },
  {
    field: "triage_type",
    flex: 1,
    headerName: "Issue Type",
    valueGetter: (params) =>
      formatTroubleshootLabel(params.row.details.triage.type),
  },
  {
    field: "triage_status",
    flex: 1,
    headerName: "Initial Resolution",
    valueGetter: (params) =>
      formatTroubleshootLabel(params.row.details.triage.status),
  },
  {
    field: "return_type",
    flex: 1,
    headerName: "Return Type",
    valueGetter: (params) =>
      formatTroubleshootLabel(params.row.details.triage?.return_type || ""),
  },
  {
    field: "intake_resolution",
    flex: 1,
    headerName: "Resolution",
    valueGetter: (params) =>
      formatTroubleshootLabel(params.row.details?.intake?.resolution || ""),
  },
];

function HistoryTabContent() {
  const [ticket, setTicket] = useState([]);
  const { hubId } = useParams();
  const history = useHistory();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
    totalCount: 0,
  });

  const onRowClick = (row) => {
    const searchParamsString = new URLSearchParams({
      ticket: row.id,
    }).toString();
    history.push(`/hubs/${hubId}/troubleshoot#history?${searchParamsString}`);
  };

  useEffect(() => {
    const getTickets = debounce(async () => {
      const res = await get(`/troubleshoot/tickets`, {
        hub_id: hubId.substring(0, 10),
        limit: pagination.pageSize,
        offset: pagination.page * pagination.pageSize,
      });
      if (res.status === 200) {
        setTicket(res.data.items);
        setPagination((pre) => ({
          ...pre,
          totalCount: res.data.count,
        }));
      } else {
        enqueueSnackbar(res.data.detail, {
          variant: "error",
        });
      }
    }, 300);
    getTickets();
    return getTickets.cancel;
  }, [pagination.page, pagination.pageSize, hubId]);

  return (
    <Stack sx={{ m: "24px" }}>
      <Typography sx={{ fontSize: 20, fontWeight: 600, mb: "10px" }}>
        Issue History
      </Typography>
      <TroubleshootTable
        columns={COLUMNS}
        rows={ticket}
        setPagination={setPagination}
        pagination={pagination}
        onRowClick={({ row }) => {
          onRowClick(row);
        }}
      />
    </Stack>
  );
}

export default HistoryTabContent;
