import { endOfDay, sub } from "date-fns";
import { debounce } from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { DOCS } from "../../utils/constants";

import { get } from "../../utils/io";
import MainContentContainer from "../Layouts/MainContentContainer";
import MeasureList from "../MeasureList";
import MeasureSearchForm from "./MeasureSearchForm";

const getOneMonthAgo = () => sub(new Date(), { months: 1 });

const getEndOfDay = () => endOfDay(new Date());
const emptySearchInputs = {
  macAddress: "",
  hubId: "",
  status: "",
  takenAfterDate: getOneMonthAgo(),
  takenBeforeDate: getEndOfDay(),
};

function Measures() {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    page: 0,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [searchInputs, setSearchInputs] = useState({ ...emptySearchInputs });
  const [autoRefreshMeasure, setAutoRefreshMeasure] = useState(false);
  const [sortModel, setSortModel] = useState([]);

  const getMeasureList = useCallback(
    async (searchs, paginations, sortField) => {
      const res = await get("/measures", {
        esn: searchs.hubId,
        device_mac: searchs.macAddress,
        status: searchs.status,
        taken_after: searchs.takenAfterDate.toISOString(),
        taken_before: searchs.takenBeforeDate.toISOString(),
        limit: paginations.pageSize,
        offset: paginations.page * paginations.pageSize,
        ordering: sortField,
      });
      if (res?.status === 200) {
        setTotalCount(res.data.total_count);
        setSearchResults(res.data.measures);
      } else {
        return enqueueSnackbar("Unable to retrieve measurements", {
          variant: "error",
        });
      }
      return null;
    },
    []
  );

  const onSelectionChanged = (selectionModel) => {
    setSelectedMeasures(selectionModel);
  };

  const onChangePage = async (page) => {
    setPagination((pre) => ({
      ...pre,
      page,
    }));
  };

  const onChangeRowPerPage = (pageSize) => {
    setPagination((pre) => ({ ...pre, pageSize }));
  };

  const refreshMeasureList = () => setPagination({ ...pagination });

  const updateMeasureGrid = useCallback(() => {
    const debouncedUpdate = debounce(async () => {
      setLoading(true);
      const sortField = sortModel[0]?.field;
      const sortDirection = sortModel[0]?.sort === "desc" ? "-" : "";
      const sortParam = sortField ? `${sortDirection}${sortField}` : undefined;

      await getMeasureList(searchInputs, pagination, sortParam);
      setLoading(false);
    }, 300);

    debouncedUpdate();
    return debouncedUpdate.cancel;
  }, [getMeasureList, pagination, searchInputs, sortModel, setLoading]);

  useEffect(() => {
    const cleanUp = updateMeasureGrid();
    return cleanUp;
  }, [updateMeasureGrid]);

  useEffect(() => {
    if (autoRefreshMeasure) {
      const interval = setInterval(updateMeasureGrid, 10000);
      return () => clearInterval(interval);
    }
    return null;
  }, [autoRefreshMeasure, updateMeasureGrid]);

  return (
    <MainContentContainer title="Measures" docsUrl={DOCS.MEASURES.url}>
      <MeasureSearchForm
        onSubmit={setSearchInputs}
        isSearchDisabled={loading}
      />
      <MeasureList
        checkbox
        loading={loading}
        rows={searchResults}
        onSelectionChanged={onSelectionChanged}
        pageSize={pagination.pageSize}
        page={pagination.page}
        onPageSizeChange={onChangeRowPerPage}
        rowsPerPageOptions={[20, 25, 50, 100]}
        rowCount={totalCount}
        onPageChange={onChangePage}
        refreshMeasureList={refreshMeasureList}
        selectedMeasures={selectedMeasures}
        searchResults={searchResults}
        searchInputs={searchInputs}
        setSortModel={setSortModel}
        sortingMode="server"
        setAutoRefreshMeasure={setAutoRefreshMeasure}
        autoRefreshMeasure={autoRefreshMeasure}
      />
    </MainContentContainer>
  );
}

export default Measures;
